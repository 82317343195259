import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@material-ui/core';
import CustomSelect from '../components/CustomSelect';
import Field from '../components/Field';

import { useBuilds, useUpdateGroup, useBranches } from '@domatic/query';
import { useInput } from '@domatic/hooks';

const GroupEdit = ({ group }) => {
    const name = useInput(group.name, '', 'Name');
    const branch = useInput(group.branch, '', 'Branch');
    const build = useInput(group.version, '', 'Version');

    const { data: branches } = useBranches('hub');
    const { data: builds } = useBuilds('hub', branch.value, 'aarch64');
    const { mutate: updateGroup } = useUpdateGroup();

    const branchList = useMemo(() => branches?.map(branch => ({
        name: branch.name,
        id: branch.name
    })), [branches]);

    const buildList = useMemo(() => builds?.map(build => {
        const branch = build.branch;
        const id = String(build.version);
        const name = String(build.version);
        const version = String(build.version);
        return { id, name, version, branch };
    }), [builds]);

    const doReset = useCallback(() => {
        name.reset();
        branch.reset();
        build.reset();
    }, [name, branch, build]);

    const doUpdate = useCallback(() => {
        updateGroup({
            id: group.id,
            name: name.value,
            branch: branch.value,
            version: build.value
        }, {
            onSuccess: doReset
        });
    }, [updateGroup, group.id, name.value, branch.value, build.value, doReset]);

    const changed = (name.changed || branch.changed || build.changed);
    const valid = (name.valid && branch.valid && build.valid);
    const enableSubmit = changed && valid;

    return (
        <Box flexGrow={1} display="flex" flexDirection="row" alignItems="baseline">
            <Field field={name} required fullWidth />
            &nbsp;&nbsp;
            <CustomSelect field={branch} data={branchList} required fullWidth />
            &nbsp;&nbsp;
            <CustomSelect field={build} data={buildList} required fullWidth />
            &nbsp;&nbsp;
            <Box display="flex" flexDirection="column" alignItems="center" flexGrow={0}>
                <Button onClick={doUpdate} color='primary' disabled={!enableSubmit} >Update</Button>
            </Box>
            &nbsp;&nbsp;
            <Box display="flex" flexDirection="column" alignItems="center" flexGrow={0}>
                <Button onClick={doReset} color='primary' disabled={!enableSubmit} >Reset</Button>
            </Box>
        </Box >
    );
};

GroupEdit.propTypes = {
    group: PropTypes.object
};

export default GroupEdit;
