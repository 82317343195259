import axios from 'axios';

import { useMutation } from './queryClient';
import { useInfinite, useCreateObject, useRetrieveObject, useUpdateObject, useDeleteObject } from './utils';
import { getSubscriptionObject } from './subscription';
import { usePut, usePost } from './utils';

export const useHubCapture = (buildingId) => usePut('/hub/capture', { buildingId });

export const useGroups = () => useInfinite(['hub', 'group'], '/hub/upgrade/groups', { auto: true });
export const useUpdateGroup = () => useUpdateObject(['hub', 'group'], '/hub/upgrade/group');
export const useCreateGroup = () => useCreateObject(['hub', 'group'], '/hub/upgrade/group');

export const useCreateApplication = () => useCreateObject(['hubApplications'], '/hub/application');
export const useUpdateApplication = () => useUpdateObject(['hubApplications'], '/hub/application');
export const useDeleteApplication = () => useDeleteObject(['hubApplications'], '/hub/application');

export const getCachedHub = hubId => getSubscriptionObject(['buildingHubs'], hubId);
export const useHub = id => useRetrieveObject(['hub', id], `/hub/find/${id}`);
export const useUpdateHub = () => useUpdateObject(['hub'], '/hub');

export const Hub = {
    useByTemplateId: templateId => useRetrieveObject(['hub', 'template', templateId], `/hub/template/${templateId}`),
};

export const useBackups = (hubId, options) => useInfinite(['backup', hubId], `/hub/backup/list/${hubId}`, options);

export const useHubBySerial = serial => useRetrieveObject(['hub', serial], `/hub/lookup/${serial}`);

export const useUpdateSetting = hubId => useUpdateObject(['setting', hubId], `/hub/setting/${hubId}`);

// export const useDevices = hubId => useRetrieveArray(['device', hubId], `/hub/devices/${hubId}`);
// export const useCircuits = ({ hubId, sortBy, reverse }) => useInfinite(['infinite-circuits', hubId], `/hub/circuits/${hubId}`, { sortBy, reverse });

export const useDeviceControl = (deviceId, data) => usePost(`/hub/device/control/${deviceId}`, data);

export const useSetDevice = hubId => useUpdateObject(['device', hubId], `/hub/device/${hubId}`);
export const useSetCircuit = hubId => useUpdateObject(['circuit', hubId], `/hub/circuit/${hubId}`);

const launchHub = hub => async () => axios.post('/hub/launch', hub);

export const useLaunchHub = hub => {
    return useMutation(launchHub(hub));
};

export const useRetireHub = () => usePut('/hub/retire');
export const useReplaceHub = () => usePost('/hub/replace');
